const translation = {
    "nb": {
        "alle-tegn": "Alle tegn",
        "sist-sett": "Sist sette tegn",
        "kategori-tittel": "Tegn i kategori [*]",
        "placeholder-alle": "Søk i [*] taktile tegn",
        "placeholder-kat": " i kategorien [*]",
        "andre-tegn": "Flere tegn i kategorien",
        "legg-til-samling": "Lagre i lister",
        "last-tegnbilde": "Last ned bilde",
        "last-video": "Last ned video",
        "del-lenke": "Del lenke",
        "kopier-lenke": "Kopier lenke",
        "kopiert": "Kopiert!",
        "nullstill": "Nullstill",
        "samling-overskrift": "Legg tegn «[*]» i lister",
        "login": "Logg inn",
        "min-bruker": "Min bruker",
        "opprett-bruker": "Opprett bruker",
        "rediger-bruker": "Rediger bruker",
        "slett-bruker": "Slett bruker",
        "glemt-passord": "Glemt passord",
        "gaa-til": "Gå til side [*]",
        "print": "Skriv ut tegn",
        "laster": "Laster",
        "input:navn": "Navn",
        "input:epost": "E-post",
        "input:passord": "Passord",
        "input:liste:navn": "Navn på liste",
        "button:list:add": "Legg til",
        "button:list:remove": "Fjern"
    },
    "en": {
        "alle-tegn": "All signs",
        "sist-sett": "Last viewed signs",
        "kategori-tittel": "Sign in category [*]",
        "placeholder-alle": "Search in [*] tactile signs",
        "placeholder-kat": " in category [*]",
        "andre-tegn": "More signs in the category",
        "legg-til-samling": "Add to lists",
        "last-tegnbilde": "Download image",
        "last-video": "Download video",
        "del-lenke": "Share link",
        "kopier-lenke": "Copy link",
        "kopiert": "Copied!",
        "nullstill": "Reset",
        "samling-overskrift": "Add sign «[*]» to lists",
        "login": "Login",
        "min-bruker": "My user",
        "opprett-bruker": "Create user",
        "rediger-bruker": "Edit user",
        "slett-bruker": "Delete user",
        "glemt-passord": "Forgot password",
        "gaa-til": "Go too site [*]",
        "print": "Print sign",
        "laster": "Loading",
        "input:navn": "Name",
        "input:epost": "Email",
        "input:passord": "Password",
        "input:liste:navn": "Name on collection",
        "button:list:add": "Add",
        "button:list:remove": "Remove"
    }
} as const;

export function key(handle: string, replace: string = null, language: 'nb' | 'en' | string = window.lang): string {
    let translated: string = translation[language.toString()][handle.toString()] || '';
    if (replace) {
        translated = translated.replace('[*]', replace);
    }
    return translated;
}

export function inline(no: string = '', en: string = ''): string {
    switch (window.lang) {
        case 'nb':
        default:
            return no;
        case 'en':
            return en;
    }
}

export const translate = {
    key,
    inline
}
