// https://markus.oberlehner.net/blog/simple-solution-to-prevent-body-scrolling-on-ios/
const $html: HTMLElement = document.documentElement,
    $body: HTMLBodyElement = document.querySelector('body');
let scrollPosition: number = 0,
    enabled: boolean = false;

export default {
    enabled,
    enable() {
        scrollPosition = window.pageYOffset;
        $body.style.overflow = 'hidden';
        $body.style.position = 'fixed';
        $body.style.top = `-${scrollPosition}px`;
        $body.style.width = '100%';
        $html.style.height = '100dvh';
        this.enabled = true;
    },
    disable() {
        $body.style.removeProperty('overflow');
        $body.style.removeProperty('position');
        $body.style.removeProperty('top');
        $body.style.removeProperty('width');
        $html.style.removeProperty('height');
        window.scrollTo(0, scrollPosition);
        this.enabled = false;
    }
};
