// import 'unfonts.css';
//kommentar bare for å kjøre build
import 'virtual:svg-icons-register';
import '@/css/app.pcss';
import 'swiper/css';
import 'lazysizes';
import 'lazysizes/plugins/bgset/ls.bgset';
import 'lazysizes/plugins/print/ls.print';
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';
import intersect from '@alpinejs/intersect';
import QRCode from 'qrcode';
import {deleteCache, getCache, isTouch, placeholder, setCache, sizeCache, videoThumb} from './util';
import scrollLock from './scroll-lock';
import {translate} from "./translation";
import {ofetch} from 'ofetch';

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import 'dayjs/locale/nb';

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(duration)
dayjs.locale('nb');

window['dayjs'] = dayjs;
window['lang'] = document.documentElement.getAttribute('lang')
window['t'] = translate;
window['isTouch'] = isTouch;

window['placeholder'] = placeholder;
window['vimeoThumb'] = videoThumb;
window['scrollLock'] = scrollLock;
window['scrollToCard'] = (e) => {
    if (e.key !== 'Tab') return;
    const $el = e.target;
    if ($el.classList.contains('card')) {
        $el.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center'
        })
    }
}

Alpine.plugin(collapse);
Alpine.plugin(intersect);

window.Alpine = Alpine;

// Egen paramenter til video/audio element
Object.defineProperty(HTMLMediaElement.prototype, 'playing', {
    get: function () {
        return !!(this.currentTime > 0 && !this.paused && !this.ended && this.readyState > 2);
    }
})
Array.prototype.random = function () {
    return this[Math.floor((Math.random() * this.length))];
}
const getUniqueListBy = (arr: any[], key: string) => {
    return [...new Map(arr.map(item => [item[key], item])).values()]
}
document.addEventListener('error', (e) => {
    const el = e.target as HTMLElement;
    if (el.nodeName == 'IMG') {
        el.classList.add('lazyerror')
    }
}, true);

window['onScrollStop'] = callback => {
    let isScrolling;
    window.addEventListener(
        'scroll',
        () => {
            clearTimeout(isScrolling);
            isScrolling = setTimeout(() => {
                callback();
            }, 150);
        },
        false
    );
};

const goToLink = (link: string = '') => {
    const currentUrl = new URL(window.location.href),
        newUrl = new URL(link, window.location.href);
    console.log(currentUrl, newUrl)
    window.location.href = newUrl.href;

    if (currentUrl.hash != newUrl.hash) {
        setTimeout(() => {
            location.reload();
        }, 200)
    }
}
window['goToLink'] = goToLink;
const demoItems = (antall = 1, tegn = []) => {
    return [...Array(antall).keys()].map(val => {
        const ny = {...tegn}
        ny.id = `1000${val}`;
        return ny;
    })
}

const getTegn = async () => {
    const key = `tegn:${lang}`;
    const isDev = document.documentElement.getAttribute("dev") == 'true';
    let tegn = getCache(key);
    if (!tegn) {
        console.info('Henter data fra api');
        const url = lang === 'en' ? '/en/api/tegn' : '/api/tegn';
        const {data} = await ofetch(url);
        tegn = data;
        if (isDev) {
            tegn = [...data, ...demoItems(500, data.random())];
        }
        setCache(key, tegn, 15 * 60);
        console.log('storage size', sizeCache());
    }
    console.log('getTegn');
    return tegn;
}

window["app"] = {
    initHeader() {
        return {
            prevScrollpos: window.pageYOffset,
            showHeader: true,
            slimHeader: false,
            showNav: false,
            mobNav: {
                myList: ['#mine-lister'].includes(window.location.hash),
                followList: ['#folger-lister'].includes(window.location.hash),
                category: false
            },
            stickyHeader: false,
            start() {
                window.onscroll = () => {
                    const currentScrollPos = window.pageYOffset;
                    this.showHeader = this.prevScrollpos > currentScrollPos;
                    this.slimHeader = currentScrollPos > 10;
                    this.stickyHeader = (currentScrollPos > document.getElementsByTagName('header')[0].getBoundingClientRect().height) || scrollLock.enabled;
                    this.prevScrollpos = currentScrollPos;
                }
                this.$watch('showNav', (show: boolean) => show ? scrollLock.enable() : scrollLock.disable());
                this.$watch('mobNav', obj => {
                    this.showNav = obj.myList || obj.followList || obj.category;
                });
                this.$nextTick(() => {
                    this.showNav = ['#mine-lister', '#folger-lister'].includes(window.location.hash)
                })
            },
            toggleNav(name: string = '') {
                this.mobNav = {
                    myList: name === 'my' ? (this.mobNav.myList = !this.mobNav.myList) : false,
                    followList: name === 'follow' ? (this.mobNav.followList = !this.mobNav.followList) : false,
                    category: name === 'cat' ? (this.mobNav.category = !this.mobNav.category) : false,
                }
            }
        }
    },
    hentSession() {
        return {
            user: {
                isAdmin: false,
                isGuest: true
            },
            csrf: null,
            admin: {
                show: localStorage.getItem('showAdmin') === 'true',
                lenke: null,
                url: '/admin'
            },
            async setupSession() {
                await this.hentUser();

                this.chechHash();

                this.$watch('editId', async (value: number) => {
                    // console.log('editId', value)
                    await this.hentEdit(value);
                });

                this.$watch('admin.show', (value: boolean) => {
                    localStorage.setItem('showAdmin', value.toString());
                });

                this.$watch('params.type', (value: string | null) => {
                    if (value) {
                        const popup = document.getElementById("popup-lukk");
                        if (!isTouch()) {
                            this.$nextTick(() => {
                                popup?.focus();
                            })
                        }
                    }
                })
            },
            async hentUser() {
                /*const session = await ofetch('/admin/actions/users/session-info', {
                    headers: {
                        Accept: 'application/json'
                    }
                })
                console.log(session)*/
                const response = await ofetch('/api/me', {
                    headers: {
                        Accept: 'application/json'
                    }
                })
                if (response) {
                    this.csrf = response.csrf;
                    delete response.csrf;
                    this.user = response;
                    if (this.user.isAdmin) {
                        try {
                            // @ts-ignore
                            Sentry.setUser({
                                email: this.user.email
                            });
                        } catch (error) {
                            console.warn(error);
                        }
                        await this.adminbar();
                    }
                }

                await this.getLister()

                if (this.editId) {
                    await this.hentEdit(this.editId);
                }
            },
            async hentEdit(id = null): Promise<boolean> {
                if (!this.csrf || !this.user.isAdmin || id == null) {
                    return false;
                }
                const url = lang === 'en' ? '/en/api/edit' : '/api/edit';
                const {data} = await ofetch(`${url}?ids=${id}`);
                this.admin.lenke = data[0];

                return true;
            },
            async adminbar() {
                if (document.getElementById("adminbar")) return;
                const {default: adminbar} = await import('@/template/adminbar.html?raw');
                let elem = document.createElement('div');
                elem.id = "adminbar";
                elem.classList.add('print:hidden');
                elem.innerHTML = adminbar;
                document.body.appendChild(elem);
            }
        };
    },
    initListe() {
        return {
            lister: {
                currentTegnId: null,
                mine: [],
                folger: [],
                deltListe: []
            },
            alleTegn: null,
            sort: getCache('listeSort') || 'index',
            toggleSortering() {
                this.sort = this.sort === 'index' ? 'alf' : 'index';
                setCache('listeSort', this.sort);
            },
            mineLister() {
                if (this.sort === 'index') {
                    return this.lister.mine;
                } else {
                    return [...this.lister.mine].sort((a, b) => a.title.localeCompare(b.title, 'no', {sensitivity: 'base'}));
                }
            },
            inList(tegnId: number, listId: number = null) {
                if (listId) {
                    return !!this.lister.mine.find(item => item.tegn.includes(tegnId) && item.id === listId);
                }
                return !!this.lister.mine.find(item => item.tegn.includes(tegnId));
            },
            async currentTegn() {
                if (!this.alleTegn) {
                    this.alleTegn = await getTegn();
                }
                return (this.alleTegn || []).find(item => this.lister.currentTegnId == item.id);
            },
            currentListe() {
                return [...this.lister.mine, ...this.lister.folger, ...this.lister.deltListe].find(item => item.reference === this.filter.liste);
            },
            resetLister() {
                this.lister = {...this.lister, ...{mine: [], folger: [], deltListe: []}}
            },
            async getLister() {
                if (!this.user.isGuest) {
                    const {data} = await ofetch('/api/lister');
                    this.lister.mine = data.mine;
                    this.lister.folger = data.folger || [];
                }

                const urlParams = new URLSearchParams(window.location.search);
                const listRef = urlParams.get('liste');
                if (listRef && ![...this.lister.mine, ...this.lister.folger, ...this.lister.deltListe].find(item => item.reference == listRef)) {
                    const {data} = await ofetch(`/api/liste/${listRef}`);
                    this.lister.deltListe = data ? [data] : [];
                }
            },
            async updateBrukerListe(type: ('add' | 'remove') = 'add', listId) {
                const response = await ofetch(`/api/liste/${type}`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'X-CSRF-Token': this.csrf
                    },
                    body: {listId: listId}
                }).catch(err => {
                    console.log("[fetch request error]", err.data);
                });
                console.log(response)
                if (response.success) {
                    await this.getLister();
                }
            },
            async toggleItem(tegnId: number = null, listId: number = null, type: 'add' | 'remove' = null) {
                if (!type) {
                    type = this.inList(tegnId, listId) ? 'remove' : 'add';
                }
                let errors = null;
                const {action, error} = await ofetch(`/admin/actions/wishlist/items/${type}`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'X-CSRF-Token': this.csrf
                    },
                    body: {listId: listId, elementId: tegnId}
                }).catch(err => {
                    errors = err.data;
                    console.log("[fetch request error]", errors);
                });
                if (error) {
                    errors = error;
                }
                if (!errors) {
                    console.log(`${action} liste`, listId)
                    this.lister.mine = this.lister.mine.map(list => {
                        if (list.id == listId) {
                            if (action === 'add') {
                                list.tegn.push(tegnId);
                            } else {
                                list.tegn = list.tegn.filter(item => item !== tegnId)
                            }
                        }
                        return list;
                    })
                    this.$dispatch('success')
                } else {
                    console.log('error', errors)
                    this.$dispatch('error', {error: errors})
                }
            },
            async deleteList(listId: number) {
                let error = null;
                await ofetch('/admin/actions/wishlist/lists/delete', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'X-CSRF-Token': this.csrf
                    },
                    body: {listId: listId}
                }).catch(err => {
                    error = err.data;
                    console.error("[fetch request error]", err.data);
                });
                if (!error) {
                    this.lister.mine = this.lister.mine.filter(item => item.id !== listId)
                    this.$dispatch('success')
                } else {
                    this.$dispatch('error', {error})
                }

            },
        }
    },
    initSok(checkParams = false, initMinisearch = true) {
        return {
            tegn: [],
            loadingTegn: false,
            antall: 0,
            filter: {
                sisteTegn: null,
                liste: null,
                kategori: null,
                ids: null,
                sok: ''
            },
            disablePush: false,
            defaultFilter: null,
            search: {
                tegn: null,
                kategori: null
            },
            takTitle: '',
            top: 150,
            bottom: 0,
            async init() {
                console.log('initSok', lang);
                this.defaultFilter = {...this.filter};
                this.loadingTegn = true;
                const tegn = await getTegn();
                Object.freeze(tegn);
                if (initMinisearch) {
                    const {default: MiniSearch} = await import('minisearch');
                    let tegnSearch = new MiniSearch({
                        fields: ['title', 'tags'], // fields to index for full-text search
                        storeFields: ['title', 'url', 'category', 'video'], // fields to return with search results
                        searchOptions: {
                            boost: {title: 1.5, tags: 0.7},
                            prefix: true, // prefix search, “foo” matches “foobar”
                            fuzzy: true // fuzzy search, “foo” matches “fox”
                        }
                    })
                    tegnSearch.addAll(tegn);
                    this.search.tegn = tegnSearch;

                    const katSearch = new MiniSearch({
                        idField: 'value',
                        fields: ['label', 'value'], // fields to index for full-text search
                        storeFields: ['label', 'value'],
                        searchOptions: {
                            prefix: true, // prefix search, “foo” matches “foobar”
                            fuzzy: true // fuzzy search, “foo” matches “fox”
                        }
                    });
                    const kats = tegn.reduce((obj, item) => {
                        obj = obj.concat(item.category)
                        return obj;
                    }, [])
                    katSearch.addAll(getUniqueListBy(kats, 'value'));
                    this.search.kategori = katSearch;
                }
                this.tegn = tegn;
                this.$nextTick(() => this.loadingTegn = false);
                if (checkParams) {

                    this.$watch('user.isGuest', (val, old) => {
                        if (val && !old) {
                            this.filter.liste = null
                        }
                    });

                    this.updateFilter();
                    this.$watch('filter', () => {
                        if (!this.disablePush) {
                            this.updateUrl();
                        }

                        this.showNav = ['#mine-lister', '#folger-lister'].includes(window.location.hash);

                        this.toggleNav();

                        setTimeout(() => {
                            const top = Math.ceil(this.$el.getBoundingClientRect().top - document.getElementsByTagName('header')[0].getBoundingClientRect().height + window.scrollY) - 14;
                            // console.log(top, window.scrollY);
                            if ((window.scrollY - 14) > top) {
                                window.scrollTo({
                                    top: top,
                                    behavior: 'smooth'
                                })
                            }
                            this.calculate();
                        }, window.innerWidth <= 768 ? 150 : 0);
                    })

                    window.addEventListener("popstate", () => {
                        this.disablePush = true;
                        this.updateFilter();
                        this.$nextTick(() => {
                            this.disablePush = false;
                        })
                    });
                }

                this.calculate();
            },
            reset() {
                this.filter = {...this.defaultFilter};
            },
            updateFilter(params: URLSearchParams = new URLSearchParams(window.location.search)) {
                let filter = {...this.defaultFilter};
                for (const param of params) {
                    const key = param[0] || null,
                        value = param[1] || null;
                    if (key == 'tag' || key == 'sok') {
                        filter.sok = key == 'tag' ? `#${value}` : value;
                    } else if (key == 'kategori') {
                        filter.kategori = value;
                    } else if (key == 'liste') {
                        filter.liste = value;
                    } else if (key == 'sisteTegn') {
                        filter.sisteTegn = true;
                    }
                }
                this.filter = filter;
            },
            updateUrl() {
                const url = new URL(window.location.pathname, window.location.href);
                const params = {...this.filter, ...this.pages};
                Object.keys(params).forEach(key => {
                    const value = params[key];
                    if (!!value && key !== 'ids') {
                        const tag = key === 'sok' && value.startsWith('#');
                        if (tag) {
                            url.searchParams.set(tag ? 'tag' : key, value.replace('#', ''));
                        } else {
                            url.searchParams.set(key, value);
                        }
                    }
                });
                if (window.location.href !== url.href) {
                    history.pushState({}, "", url.href);
                }
            },
            katHint(search: String = '') {
                return this.search.kategori.search(search)
            },
            filtrert(params = {sok: '', kategori: null, ids: null, ekskluder: null}) {
                const filter = {
                    kategori: params.kategori || this.filter.kategori,
                    ids: params.ids || this.filter.ids,
                    sok: params.sok || this.filter.sok
                }
                let resultat = [];
                if (filter.sok && this.search.tegn) {
                    const tag = filter.sok.startsWith('#'),
                        sok = tag ? filter.sok.replace('#', '') : filter.sok;
                    resultat = this.search.tegn.search(sok, {
                        fields: tag ? ['tags'] : null,
                        fuzzy: !tag
                    });
                } else {
                    resultat = this.tegn || [];
                }

                if (filter.kategori && resultat.length > 0) {
                    resultat = resultat.filter((tegn) => {
                        return tegn.category.find(item => item.value === filter.kategori)
                    })
                }

                if (filter.ids && filter.ids.length > 0 && resultat.length > 0) {
                    resultat = filter.ids.map(id => resultat.find(item => item.id === id)).filter(Boolean);
                }

                if (this.filter.liste) {
                    resultat = this.currentListe()?.tegn.map(id => resultat.find(item => item.id === id)).filter(Boolean) || [];
                } else if (this.filter.sisteTegn && resultat.length > 0) {
                    resultat = (getCache('sisteTegn') || [])?.map(id => resultat.find(item => item.id === id)).filter(Boolean) || [];
                }

                if (params.ekskluder && resultat.length > 0) {
                    resultat = resultat.filter((tegn) => {
                        return tegn.id !== params.ekskluder;
                    })
                }
                this.antall = resultat.length;
                return resultat;
            },
            isAlleTegn(): boolean {
                return !this.filter.kategori && !this.filter.sisteTegn && !this.filter.liste;
            },
            isActive(kategori = null): boolean {
                return this.filter.kategori === kategori;
            },
            isActiveParent(children = []): boolean {
                return children.includes(this.filter.kategori || this.filter.liste);
            },
            setKategori(kategori: string | null = null, toggle: boolean = false) {
                if (toggle) {
                    this.filter = {...this.defaultFilter, ...{kategori: this.filter.kategori === kategori ? null : kategori}};
                } else {
                    this.filter = {...this.defaultFilter, ...{kategori: kategori}};
                }
            },
            getKatTitle() {
                this.$nextTick(() => {
                    //@ts-ignore
                    this.takTitle = [...document.querySelectorAll<HTMLElement>(".tegn-meny > li.active > button .tittel")].pop()?.textContent
                    // console.log();
                })
            },
            isListe(type: ('mine' | 'folger' | 'deltListe')): boolean {
                if (!this.filter.liste) return false;
                // console.log(this.lister[type], type)
                return !!this.lister[type]?.find(item => item.reference == this.filter.liste)
            },
            setSisteTegn() {
                this.filter = {...this.defaultFilter, ...{sisteTegn: true, ids: null}};
            },
            get placeholder(): string {
                let tekst = translate.key('placeholder-alle', (this.antall || 0).toString());
                if (this.filter.kategori) {
                    const tittel = (this.takTitle || '').toLowerCase();
                    tekst += translate.key('placeholder-kat', tittel)
                }
                return tekst;
            },
            get tittel(): string {
                if (this.filter.kategori) {
                    return this.takTitle || '';
                } else if (this.filter.liste) {
                    return this.currentListe()?.title || 'Ingen liste';
                } else if (this.filter.sisteTegn) {
                    return translate.key('sist-sett');
                }
                return translate.key('alle-tegn');
            },

            calculate() {
                if (window.innerWidth > 768 || scrollLock.enabled) return;
                const $footer = document.getElementsByTagName('footer')[0]
                const top = this.$el.getBoundingClientRect().top;
                const bottom = window.innerHeight - $footer.getBoundingClientRect().top;
                this.top = top > 0 ? (top + 2) : 0;
                this.bottom = bottom > 0 ? (bottom + 2) : 0;
                // console.log('calculated', top, bottom)
            }
        }
    },
    initInlineVideo() {
        return {
            player: null,
            loaded: false,
            hover: 0,
            init() {
                if (this.player) return;
                const player = this.$el as HTMLVideoElement;

                player.onmouseleave = () => {
                    // console.log('mouseleave')
                    this.hover = 0;
                    this.toggle()
                }
                player.onmouseenter = () => {
                    // console.log('mouseenter')
                    this.hover = 1;
                    if (!this.loaded) {
                        this.player.setAttribute('preload', 'metadata')
                    }
                    this.toggle();
                }

                player.onloadedmetadata = (event) => {
                    // console.log('loadedmeta', event)
                    this.loaded = event.eventPhase >= 2;
                    if (this.loaded && this.hover) {
                        player.play();
                    }
                }

                this.player = player;
            },
            toggle() {
                const player = this.player;
                if (!player || !this.loaded) return;
                player.playing ? player.pause() : player.play()
            },
        }
    },
    initVideo(id: number = null) {
        return {
            video: null,
            player: null,
            playing: false,
            loaded: false,
            speed: 1.0,
            progress: 0,
            currentTime: 0,
            totalTime: 0,
            currentId: id,
            videoWidth: 0,
            setup() {
                if (this.player) return;
                const player = this.$el as HTMLVideoElement;

                player.onloadedmetadata = (event) => {
                    this.totalTime = player.duration;
                    this.currentTime = 0;
                    this.loaded = event.eventPhase >= 2;
                    player.playbackRate = this.speed;
                    console.log('loadedmeta', event)
                }

                this.$watch('player', async (item) => {
                    const lazySrc = item.dataset?.src || null;

                    if (!lazySrc || lazySrc == item.currentSrc) return
                    console.log(lazySrc, item.currentSrc)
                    if (lazySrc.includes('.m3u8')) {
                        player.src = '';
                        const {default: Hls} = await import('hls.js');
                        const hls = new Hls();
                        // hls.loadSource(lazySrc);
                        hls.attachMedia(player);
                        hls.on(Hls.Events.MEDIA_ATTACHED, () => {
                            hls.loadSource(lazySrc);
                        });
                    } else {
                        player.src = lazySrc;
                    }
                })

                player.onplay = () => {
                    this.playing = true;
                };
                player.onpause = () => {
                    this.playing = false;
                };
                player.ontimeupdate = () => {
                    this.currentTime = player.currentTime;
                    this.progress = ((player.currentTime / this.totalTime) * 100).toFixed(0);
                };
                new ResizeObserver(() => {
                    this.videoWidth = player.offsetWidth;
                }).observe(player)
                this.player = player;
            },
            sources(quality: string = 'responsive', vid = videos) {
                if (!this.currentId || !this.player || vid?.length == 0) return null;

                const sources = vid.find(({id}) => id === this.currentId).source || [];
                if (quality == 'responsive') {
                    const maxWidth = this.player.offsetWidth || this.videoWidth;
                    const source = sources.filter(({quality}) => quality !== 'hls').sort((a, b) => b.width - a.width).find(({width}) => width <= maxWidth) || sources.pop();
                    console.log(source, sources)
                    return source;
                }
                const selected = sources.find(({rendition}) => rendition == quality);
                // console.log(selected, sources)
                return selected;
            },
            toggle() {
                const player = this.player;
                if (!player && player.fullscreenEnabled) return;
                this.playing ? player.pause() : player.play()
            },
            toggleSpeed() {
                const player = this.player;
                const speed = player.playbackRate == 1 ? 0.5 : 1.0;
                player.playbackRate = speed;
                this.speed = speed;
            },
            loadVideo(id: number = null) {
                this.loaded = false;
                this.currentId = id;
                if (!id) {
                    setTimeout(() => {
                        this.loaded = true;
                    }, 200)
                }
            },
            seek() {
                const player = this.player,
                    playing = player.playing,
                    target = this.$el,
                    rect = target.getBoundingClientRect();
                if (playing) {
                    player.pause();
                }
                player.currentTime = player.duration * (this.$event.clientX - rect.left) / rect.width;
                if (playing) {
                    player.play();
                }
            },
            fullscreen() {
                const player = this.player;
                try {
                    player.webkitEnterFullscreen();
                    player.enterFullscreen();
                } catch (e) {
                    console.warn(e)
                }

            },
            get duration() {
                return dayjs.duration(this.currentTime, 'seconds').format('mm:ss');
            },
            active(id: number = null) {
                return this.currentId === id;
            }
        }
    },
    initDownload() {
        return {
            downloading: false,
            progress: 0,
            video: {
                link: null,
                tegn: null
            },
            /*init() {
                console.log('init', this.currentId)
            },*/
            async download(url: string, name: string = null, size: number = 0) {
                if (!this.csrf || !url) return;
                this.downloading = true;
                let blob = null;
                try {
                    blob = await this.streamBlob(url, size);
                } catch (e) {
                    blob = await ofetch(url, {responseType: 'blob'}).catch(err => {
                        console.log("[fetch request error]", err.data);
                    });
                }
                this.downloading = false;
                if (blob) {
                    // Lag link objektet
                    const link: HTMLAnchorElement = document.createElement('a');
                    link.style.display = 'none';
                    link.href = URL.createObjectURL(blob);
                    link.download = name || '';

                    // It needs to be added to the DOM so it can be clicked
                    document.body.appendChild(link);
                    link.click();

                    // To make this work on Firefox we need to wait
                    // a little while before removing it.
                    setTimeout(() => {
                        URL.revokeObjectURL(link.href);
                        link.parentNode.removeChild(link);
                    }, 200);
                }
            },
            async streamBlob(url: string, size: number = 0): Promise<Blob | null> {
                const response = await ofetch.raw(url, {responseType: 'stream'})
                    .catch((err) => {
                        console.log("[fetch request error]", err.data);
                    });
                if (!response?.body) return null;

                const reader = response.body.getReader();
                const chunks = [];
                const totalSize = parseInt(response.headers.get('Content-Length') || size.toString() || '0');
                // console.log(response.headers)
                let progressSize = 0;
                while (true) {
                    const {done, value} = await reader.read();
                    if (done) {
                        this.progress = 0;
                        console.log('done');
                        break;
                    }
                    progressSize += value.length;
                    const newProgress = parseInt(String(progressSize / totalSize * 100));
                    console.log(newProgress, progressSize, totalSize, url);
                    if (this.progress !== newProgress) {
                        this.progress = newProgress;

                    }
                    chunks.push(value)
                }
                return new Blob(chunks, {type: response.headers.get('content-type')})
            },
            canDownload(url = null) {
                return !!['assets.mustasj.dev'].find(item => url.includes(item));
            },
            async getVideos(id: number = null, tegnId: number = null) {
                this.downloading = true;
                const {data} = await ofetch(`/api/video-urls/${id}`)
                const videos = data.sort((a, b) => a.size - b.size)
                this.video.link = videos.pop();
                if (tegnId) {
                    this.video.tegn = getCache(`tegn:${lang}`).find(item => tegnId === item.id);
                }
                this.downloading = false;
            },
        }
    },
    initSlideshow() {
        return {
            swiper: null,
            async setup(el = this.$el, options = {}) {
                const {Swiper} = await import('swiper');
                const {Navigation, Keyboard} = await import('swiper/modules');
                console.log('init slideshow');
                let baseOptions: object = {
                    modules: [Navigation, Keyboard],
                    grabCursor: true,
                    slidesPerView: 'auto',
                    spaceBetween: 32,
                    keyboard: {
                        enabled: true,
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }
                }
                baseOptions = Object.assign(baseOptions, options)
                this.swiper = new Swiper(el, baseOptions);
            }
        }
    },
    initSkjema() {
        return {
            visSkjema: false,
            sender: false,
            params: {},
            errors: [],
            success: null,
            visError() {
                return this.errors.length > 0;
            },
            visSuccess() {
                return this.success;
            },
            togglePopup(params: { type?: string } = null, show: boolean | null = null, ms: number = 0) {
                setTimeout(() => {
                    this.params = params || {};
                    this.errors = [];
                    this.success = null;
                    this.visSkjema = show !== null ? show : !this.visSkjema;
                }, ms);
            },
            chechHash() {
                console.log('check hash')
                const hash = window.location.hash.substring(1) || null;
                if (hash?.includes('popup-')) {
                    this.togglePopup({type: hash.replace('popup-', '')})
                }
            },
            async send() {
                this.sender = true;
                this.errors = [];
                const data = new FormData(this.$el);
                let url = new URL(this.$el.getAttribute('action') || '/', window.location.href);
                const json = await ofetch(url.href, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'X-CSRF-Token': this.csrf,
                        'X-Requested-With': 'XMLHttpRequest'
                    },
                    body: data
                }).catch((err) => {
                    console.error("[fetch request error]", err.data);
                    this.errors = [err.data];
                });

                if (json?.error) {
                    this.errors.push({message: json.error})
                }
                if (this.errors.length === 0) {
                    this.success = true;
                    console.log(this.params?.type)
                    if (this.params?.type == 'login') {
                        const isLogout = url.pathname.includes('logout');
                        if (isLogout) {
                            this.resetLister();
                            this.togglePopup({type: 'logout'}, true);
                        }
                        this.togglePopup(null, false, isLogout ? 2000 : 1000);
                        await this.hentUser();
                    } else if (this.params?.type == 'list-add') {
                        await this.getLister();
                        if (this.params?.tegnId) {
                            await this.toggleItem(this.params.tegnId, json.id, 'add');
                            this.lister.currentTegnId = this.params.tegnId;
                            this.$dispatch('toast', {msg: `Tegn lagt til i ny liste`});
                            this.togglePopup({type: 'list-add-tegn'}, true, 1000);
                        } else {
                            this.togglePopup(null, false, 1000);
                        }
                    } else if (this.params?.type == 'slett-bruker') {
                        this.resetLister();
                        await this.hentUser();
                    }

                    // Dette brukes not til forms
                    if (json.returnUrl && json.onSuccess == 'redirect-return-url') {
                        window.location = json.returnUrl;
                    }
                    this.$dispatch('success')
                } else {
                    this.$dispatch('error');
                }

                this.sender = false;
            },
        }
    },
    addSisteTegn(id: number) {
        let tegn: Number[] = getCache('sisteTegn') || [];
        const firstTegn = tegn[0] || null;
        if (firstTegn === id) return;
        tegn.unshift(id);
        console.log('sisteTegn lagt til', id);
        return setCache('sisteTegn', [...new Set(tegn)].filter(Boolean), 2630000);
    },
    initCopy() {
        return {
            copied: false,
            async copyUrl(text: string = null) {
                try {
                    let url = text || location.href,
                        hash = location.hash,
                        index_of_hash = url.indexOf(hash) || url.length,
                        hashless_url = url.substr(0, index_of_hash);

                    // console.log(hashless_url || url);
                    await navigator.clipboard.writeText(hashless_url || url);
                    this.copied = true;
                    setTimeout(() => {
                        this.copied = false;
                    }, 1000);
                    console.log('Page URL copied to clipboard');
                } catch (err) {
                    console.error('Failed to copy: ', err);
                }
            }
        }
    },
    initCacheDetection() {
        return {
            hasCache: false,
            caches: [],
            async setup(checkHead: boolean = false) {
                if (checkHead) {
                    const url = new URL(window.location.pathname, window.location.href);
                    const {headers} = await fetch(url, {method: 'HEAD', headers: {'Accept': 'application/json'}});
                    if (headers.get('Server') === 'cloudflare') {
                        this.caches.push({
                            label: 'Cloudflare',
                            status: headers.get('Cf-Cache-Status') || null,
                            time: headers.get('Last-Modified') || null
                        });
                    }
                }

                const isBlitz = (document.lastChild.nodeValue ?? '').includes('Blitz');
                if (isBlitz) {
                    const blitzString = document.lastChild.nodeValue.toString().trim();
                    this.caches.push({
                        label: 'Blitz',
                        status: blitzString.includes('Cached by') ? 'HIT' : 'UNKNOWN',
                        time: blitzString.split(' ').pop()
                    });
                }

                this.hasCache = this.caches.length > 0;
                // console.info('cache', this.hasCache, this.caches);
            },
            async refreshCache(url: string = null) {
                const refreshUrl = url ? new URL(url) : new URL(window.location.pathname, window.location.href);
                console.log(window.location, refreshUrl.toString())
                await ofetch('/actions/blitz/cache/refresh-urls', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'X-CSRF-Token': this.csrf,
                        'X-Requested-With': 'XMLHttpRequest'
                    },
                    body: {
                        key: 'UEP8wcr-uqz9teb6edp',
                        urls: [refreshUrl.toString()]
                    }
                }).catch((err) => {
                    console.log("[fetch request error]", err.data);
                    return false
                });
                return true;
            },
            slettTegnCache() {
                deleteCache('tegn:nb');
                deleteCache('tegn:en');
                return true;
            }
        }
    },
    initQrCode(url: string = '', options: object = {}) {
        return {
            svg: null,
            async init() {
                this.svg = await QRCode.toString(url, options)
            }
        }
    },
    isSticky(margin: string = '-1px 0px 0px 0px') {
        return {
            sticky: false,
            init() {
                const observer = new IntersectionObserver(([e]) => {
                    // console.log(e.intersectionRatio, e)
                    this.sticky = e.intersectionRatio < 1;
                }, {
                    rootMargin: margin,
                    threshold: [1],
                });

                observer.observe(this.$el);
            }
        }
    },
    deleteCache
}
document.addEventListener('lazyloaded', function (e) {
    const parent = e.target?.parentElement ?? null;
    if (parent && parent.classList.contains('spinner-loader')) {
        parent.classList.remove('spinner-loader');
    }
});
Alpine.start();
