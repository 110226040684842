export function setCache(key: string, value: string | [] | {}, sec: number = 31556926) {
    const now = new Date();

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
        data: value,
        expiry: now.getTime() + (sec * 1000)
    };
    return localStorage.setItem(key, JSON.stringify(item));
}

export function getCache(key: string): (any[] | null) {
    const itemStr = localStorage.getItem(key);

    // if the item doesn't exist, return null
    if (!itemStr) {
        return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key);
        return null;
    }
    return item.data;
}

export function deleteCache(key: string) {
    return localStorage.removeItem(key);
}

export function sizeCache(): string {
    let allStrings = '';
    for (const key in window.localStorage) {
        if (window.localStorage.hasOwnProperty(key)) {
            allStrings += window.localStorage[key];
        }
    }
    return allStrings ? 3 + ((allStrings.length * 16) / (8 * 1024)).toFixed(2) + ' KB' : 'Empty (0 KB)';
}


interface Options {
    leading?: boolean,
    trailing?: boolean
}

export function throttle(func: Function, wait: number, options: Options = {}): Function {
    let context, args, result;
    let timeout = null;
    let previous = 0;
    const later = function () {
        previous = options.leading === false ? 0 : Date.now();
        timeout = null;
        result = func.apply(context, args);
        if (!timeout) context = args = null;
    };
    return function () {
        const now = Date.now();
        if (!previous && options.leading === false) previous = now;
        const remaining = wait - (now - previous);
        context = this;
        args = arguments;
        if (remaining <= 0 || remaining > wait) {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
            previous = now;
            result = func.apply(context, args);
            if (!timeout) context = args = null;
        } else if (!timeout && options.trailing !== false) {
            timeout = setTimeout(later, remaining);
        }
        return result;
    };
}

export function placeholder(width: number = 0, height: number = 0) {
    return `data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='${width}' height='${height}' viewBox='0 0 ${width} ${height}' fill='none'><rect width='${width}' height='${height}'></rect></svg>`;
}

export function videoThumb(url: string = null, width: number, height: number): string {
    if (!url) return '';
    const baseUrl: string = url?.split('-d_')[0];
    return `${baseUrl}-d_${width}x${height}`
}

export function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function isNumber(n: any): boolean {
    return !isNaN(parseFloat(n)) && !isNaN(n - 0)
}

export function isTouch(): boolean {
    // @ts-ignore
    return ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator?.msMaxTouchPoints > 0);
}

